@media print {
    body * {
        visibility: hidden;
      }
      #no-print {
        display: none;
      }
      #printarea, #printarea * {
        visibility: visible;
      }
    
      #printarea {
        position: absolute;
        left: 0;
        top: 0;
      }
 }